<template>
	<div class="page model-apartments fade-in">
		<div class="filter-list flex flex-column">
			<apartments-filter ref="apartmentsFilterElement" @filter="filterChanged" >
				<apartments-table :apartments="filteredProducts"
				:current-apt="apartmentsPageCurrent" :loading="loading"
				@apartment-clicked="openApartment" 
				/>
			</apartments-filter>
		</div>
		<transition>
			<div v-if="apartmentsPageCurrent" class="apartment-details-pane">
				<apartment-modal :apartment="apartmentsPageCurrent" :show-buttons="true" :show-link="false"
					
					:navigate-on-button-click="true" :show-thumbnail="showThumbnail" :next-apartment="nextApartmentPage"
					:previous-apartment="previousApartmentPage" @close="apartmentsPageCurrent = null"
					@apartment-clicked="navigateToApartment" @previous-apartment-clicked="openApartment"
					@next-apartment-clicked="openApartment"></apartment-modal>
			</div>
		</transition>
	</div>
</template>

<script>
import ApartmentsFilter from "@/components/ApartmentsFilter.vue"
import { mapGetters } from "vuex"
import ApartmentModal from "@/components/ApartmentModal.vue"
import ApartmentsTable from "@/components/ApartmentsTable.vue"
// import ThreeScene from "@/components/ThreeScene.vue"
// import Accordion from "@/components/FpAccordion.vue"
// import FpNewIcon from "@/components/buttons/FpNewIcon";


export default {
	name: "ModelApartmentsPage",
	components: {
		ApartmentsTable,
		ApartmentModal,
		// ThreeScene,
		// FpNewIcon,
		ApartmentsFilter,
		// Accordion,
		// FpNewIcon,
		// FpBeautifullButton
	},
	computed: {
		apartmentsPageCurrent: {
			get() {
				return this.$store.getters.apartmentsPageCurrent
			},
			set(apartmentsPageCurrent) {
				this.$store.commit('apartmentsPageCurrent', apartmentsPageCurrent)

			}
		},
		showThumbnail() {
			return this.apartmentThumbnailDisplay && this.apartmentThumbnailDisplay.indexOf("apartments_page") > -1
		},
		filteredProducts() {
      const products = Array.isArray(this.products) ? this.products : Object.entries(this.products).map(e => e[1]);
			return products.sort((p1, p2) => p1['fp-sort'] - p2['fp-sort']).filter((product) => {
				let passedFilters = true;
				if (this.filter) {
					if (this.filter.building) {
						if (product.type_building) {
							if (product.type_building.id) {
								passedFilters = `${this.filter.building}` === `${product.type_building.id}`
							}
							if (!passedFilters) {
								if (product.type_building.name) {
									passedFilters = `${this.filter.building}` === `${product.type_building.name}`
								} else {
									passedFilters = false;
								}
							}
						}
					}
					if (!passedFilters) {
						return false;
					}
					if (this.filter.type) {
            if(product.apartment_type && product.apartment_type.id){
              passedFilters = `${this.filter.type}` === `${product.apartment_type.id}`
              if(!passedFilters){
                passedFilters = `${this.filter.type}` === `${product.apartment_type.name}`
              }
            }else if (product.type) {
							passedFilters = `${this.filter.type}` === `${product.type}`
						} else {
							passedFilters = false;
						}
					}
					if (!passedFilters) {
						return false;
					}
					if (this.filter.rooms) {
						if (product.type_rooms) {
							passedFilters = this.filter.rooms[0] <= +product.type_rooms && this.filter.rooms[1] >= +product.type_rooms
						} else {
							passedFilters = false;
						}
					}
					if (!passedFilters) {
						return false;
					}
					if (this.filter.quarter) {
						if (product.type_quarter) {
							passedFilters = `${this.filter.quarter}` === `${product.type_quarter}`
						} else {
							passedFilters = false;
						}
					}
					if (!passedFilters) {
						return false;
					}
					if (this.filter.plot) {
						if (product.type_plot) {
							passedFilters = `${this.filter.plot}` === `${product.type_plot}`
						} else {
							passedFilters = false;
						}
					}
					if (!passedFilters) {
						return false;
					}
					if (this.filter.floor) {
						if (typeof (product.floor) !== 'undefined') {
							passedFilters = this.filter.floor[0] <= +product.floor && this.filter.floor[1] >= +product.floor
						}
						// if(!passedFilters){
						// 		if(product.floor_name){
						// 				passedFilters = `${this.filter.floor}` === `${product.floor_name}`
						// 		}else{
						// 				passedFilters = false;
						// 		}
						// }
					}

          const staticFilterKeys = ['building', 'type', 'rooms', 'quarter', 'plot', 'floor'];
          const dynamicFilterKeys = Object.keys(this.filter).filter(key => !staticFilterKeys.includes(key));
          dynamicFilterKeys.forEach(key => {
            if (this.filter[key]) {
              if (product && product.display_fields && product.display_fields.length) {
                const dynamicField = product.display_fields.find(field => field['field'] === `product_dynamic_${key}`);
                console.log({display_fields: product.display_fields, key})
                if(dynamicField) {
                  passedFilters = `${this.filter[key]}` === `${dynamicField.value}`
                }
              } else {
                passedFilters = false;
              }
            }
          });
				}
				return passedFilters;
			});
		},
		...mapGetters([
			'isSideMenu',
			'currentProjectId',
			'filterOptions',
			'apartmentPageEnabled',
			'apartmentThumbnailDisplay',
			'products',
			'isMobile',
			'showApartments3DModel',
			'modelUrl',
			'hdrUrl'
		])
	},
	data() {
		return {
			filter: null,
			loading: false,
			nextApartmentPage: null,
			previousApartmentPage: null,
			sidePanelOpen: true,
	

		}
	},
	methods: {
		async filterChanged(filter) {
			this.filter = filter
			this.broadcastAction({
				action: 'show-building',
				building: filter.building
			})
			//await this.searchProducts()
		},
		openApartmentByTitle(title) {
			const apartment = Object.entries(this.products).map(entry => entry[1]).find((product) => {
				return product.title === title
			})
			if (apartment) {
				this.openApartment(apartment, false)
			}
		},

		openApartment(apt, broadcast = true) {
			console.log('openApartment', apt)
      console.log({
        isMobile: this.isMobile,
        apartmentsFilterElement: this.$refs.apartmentsFilterElement
      })
      if(this.$refs.apartmentsFilterElement && this.isMobile){
        this.$refs.apartmentsFilterElement.closeCollapse();
      }
			if (!this.isMobile || apt.type_building.floors_active) {
				if (broadcast) {
					this.broadcastAction({
						action: 'show-apartment',
						apartment: apt
					})
				}
				if (this.apartmentsPageCurrent) {
					if (apt.id === this.apartmentsPageCurrent.id) {
						this.closeApartment()
					} else {
						this.apartmentsPageCurrent = null
						setTimeout(() => {
							this.apartmentsPageCurrent = apt
						}, 300)
					}
				} else {
					this.apartmentsPageCurrent = apt
				}

				const keys = Object.keys(this.filteredProducts)
				const index = keys.indexOf(`${apt.id}`)
				if (index > -1) {
					const nextIndex = index + 1
					const previousIndex = index - 1
					if (keys[nextIndex]) {
						this.nextApartmentPage = this.filteredProducts[keys[nextIndex]]
					} else {
						this.nextApartmentPage = null
					}
					if (keys[previousIndex]) {
						this.previousApartmentPage = this.filteredProducts[keys[previousIndex]]
					} else {
						this.previousApartmentPage = null
					}
				}
			} 
			else {
	
				this.navigateToApartment(apt)
			}
		},
		closeApartment() {
			this.apartmentsPageCurrent = null
			this.nextApartmentPage = null
			this.previousApartmentPage = null
		},
		navigateToApartment(apt) {
			if (this.apartmentPageEnabled && apt.type_show_page) {
				this.$router.push({ name: 'apartment', params: { projectId: this.currentProjectId, apartmentId: apt.id } })
			}
		},
		listenFramebus() {
			this.$frameBus.on('message', this.onFramebusMessage)
		},
		unListenFramebus() {
			this.$frameBus.off('message', this.onFramebusMessage)
		},
		onFramebusMessage(data) {
			const { action } = data
			if (action) {
				switch (action) {
					case 'open-apartment':
						if (data["apartmentTitle"]) {
							this.openApartmentByTitle(data["apartmentTitle"])
						}
						break
					case 'close-apartment':
						this.closeApartment()
						break
					case 'goToApartmentPage':
						this.navigateToApartment(data.data)
						break
				}
			}
		},
		broadcastAction(data) {
			if (this.$frameBus) {
				this.$frameBus.emit('message', data);
			}
		}
	},
	/*beforeRouteEnter(to, from, next){
	next(vm => {
		//vm.loadProject(to)
		vm.searchProducts()
	})
},*/
	/*beforeRouteUpdate(){
		this.searchProducts()
	},*/
	mounted() {
		this.listenFramebus();
		if (this.isMobile) this.sidePanelOpen = false
	},
	unmounted() {
		this.unListenFramebus()
	}
}
</script>

<style lang="scss">
.page.model-apartments {
	display: flex;
	flex-flow: row;
	justify-content: flex-start;
	.AccordionHeaderWrapper {
		width: 100%;
		z-index: 11;
	}
	.toggleIcon {
		position: absolute;
		top: -2.2rem;
		right: 5rem;
		z-index: 15;
	}
	.apartments-filter {
		flex-grow: 1;
		z-index: 11;
		
		&.v-enter-active,
		&.v-leave-active {
			transition: transform 1s ease;
		}

		&.v-enter-from,
		&.v-leave-to {
			transform: translateX(500px);
		}
	}
	.apartments-table {
		padding: 10px;
		z-index: 11;
		
		&.v-enter-active,
		&.v-leave-active {
			transition: transform 1s ease;
		}

		&.v-enter-from,
		&.v-leave-to {
			transform: translateX(500px);
		}
	}

	.fpBeautifullBtn svg {
		fill: black;
	}

	.apartments-list {
		margin-top: 1rem;
	}

	.apartment-details-pane {

		&.v-enter-active,
		&.v-leave-active {
			transition: transform .3s ease;
		}

		&.v-enter-from,
		&.v-leave-to {
			transform: translateX(-500px);
		}
	}


	.filter-list {
		height: 100svh;
		position: absolute;
		right: 0;
		min-width: 20vw;
		z-index: 12;
		.apartments-table {
			flex-grow: 1;
		}
	}


}

.is-desktop,
.is-tablet {
	.page.model-apartments {
	
		.apartment-details-pane {
			position: absolute;
			left: 0;
		}
	}
}

.is-mobile {
	.page.model-apartments {
		flex-flow: column;
		.filter-list {
	
			height: auto;
			right: 0;
			width: 100vw;
			z-index: 26;
			.apartments-table {
				flex-grow: 1;
				padding-bottom: 10rem;
			}
		}
		.AccordionHeaderWrapper {
			width: 100%;
		}
		.apartments-table {
			max-height: 70svh;
		}
	}
}

.fp-side-menu-wrapper+.fp-app-wrapper .page.model-apartments {



	.apartments-nav-arrows {
		margin-left: 10px;
		margin-right: 10px;
	}



	.apartments-compere {
		position: absolute;
		left: auto;
		top: auto;
		right: 310px;
		bottom: auto;
		margin-left: auto;
		align-self: flex-start;
		transition: transform .5s ease;
		transform: rotate(0);
		transform-origin: 100% 100%;

		&.rotate {
			transform: rotate(-90deg) translateX(90px);
		}
	}
}

.ltr .fp-side-menu-wrapper+.fp-app-wrapper .page.model-apartments {
	.filter-list {
		right: auto;
		left: 0;
	}
}

.ltr .page.model-apartments {

	.apartment-modal {
		margin-right: 10px;
		margin-left: 0;
	}

	.apartment-details-pane {
		right: 0;
		left: auto;
		&.v-enter-from,
		&.v-leave-to {
			transform: translateX(500px);
		}
	}
	.apartments-filter {

		&.v-enter-from,
		&.v-leave-to {
			transform: translateX(-500px);
		}
	}
	.apartments-table {

		&.v-enter-from,
		&.v-leave-to {
			transform: translateX(-500px);
		}
	}
}
</style>
