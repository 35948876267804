<template>
    <div class="apartment-block">
        <div class="apartment-block-content">
            <div class="ab-header flex justify-between align-center">
                <div class="flex align-center">
                    <apartment-availability :available="apartment.available"></apartment-availability>
                    <span class="mr-1 ml-1">{{ apartment.available ? $t('פנוי') : $t('לא פנוי') }} </span>

                </div>
                <div class="flex align-center">
                    <apartment-fav-button :apt-id="apartment.id"></apartment-fav-button>
                    <close-btn @click="removeApartment(apartment.id)"></close-btn>

                </div>
            </div>
            <div class="infoWrapper">
                <div class="apt-info ">
                    <div class="bigNumber flex-item">
                        <span>{{ apartment.type }}</span>
                    </div>
                    <div class="flex-item">
                        <apartment-features-list :apartment="apartment"></apartment-features-list>

                    </div>
                </div>
                <div v-if="apartment.price" class="ab-price">
                    <span>{{ $t('מחיר') }}</span><span v-html="apartment.price"></span>
                </div>
                <div class="apt-buttons " v-if="apartment.type_building && apartment.type_building.buttons">
                    <template v-if="apartment.buttons && Array.isArray(apartment.buttons)">
                        <fp-button v-for="(button, btnInd) in apartment.buttons" :key="btnInd" :class="{active: this.currentButton && this.currentButton.label == button.label}"
                            expand="block" variant="" @click="contentChange(button)" >{{ button.label
                            }}</fp-button>
                    </template>
                </div>

            </div>
  
        </div>
        <!-- <transition appear> -->
        <div class="apartment-block-card">
            <div class="apartment-block-card-content scrollable-y">

                <building-specs v-if="contentType() === 'specifications'" :content="currentButton"
                    viewType="small"></building-specs>
                <div class="gallery-slides" v-else-if="contentType() === 'gallery'">
                    <template
                        v-if="currentButton.tabs && currentButton.tabs[0].content">
                        <div v-if="currentButton.tabs[0].content[0].gallery && currentButton.tabs[0].content[0].gallery.length == 1" 
                            :class="`img-container`">
                            <img :src="currentButton.tabs[0].content[0].gallery[0].url">
                        </div>
                        <slider-gallery
                            v-else-if="currentButton.tabs[0].content[0].acf_fc_layout === 'gallery'"
                            :slides="currentButton.tabs[0].content[0].gallery">
                            <template v-slot="{ slide }">
                                <div :class="`img-${slide.image_layout}`">
                                    <img :src="slide.url">
                                </div>
                            </template>
                        </slider-gallery>
                        <template
                            v-else-if="currentButton.tabs[0].content[0].acf_fc_layout === 'iframe'">
                            <iframe v-if="currentButton.tabs[0].content[0].src"
                                :src="currentButton.tabs[0].content[0].src"></iframe>
                        </template>
                    </template>
                </div>

            </div>
        </div>
        <!-- </transition> -->
    </div>
</template>
<script>
import ApartmentAvailability from "@/components/apartment/ApartmentAvailability.vue"
import CloseBtn from "@/components/buttons/CloseBtn";
// import FpIconButton from "@/components/buttons/FpIconButton";
import FpButton from "@/components/buttons/FpButton";
import ApartmentFeaturesList from "@/components/ApartmentFeaturesList";
import ApartmentFavButton from "@/components/buttons/ApartmentFavButton.vue"
import BuildingSpecs from "@/components/BuildingSpecs";
import SliderGallery from "@/components/SliderGallery";
export default {
    name: 'ApartmentBlock',
    components: {
        ApartmentAvailability,
        ApartmentFeaturesList,
        FpButton,
        CloseBtn,
        // FpIconButton,
        ApartmentFavButton,
        BuildingSpecs,
        SliderGallery
    },
    data() {
        return {
            currentButton: null,

        }
    },
    props: {
        apartment: {
            required: true
        },
        // apartmentContents: {
        //     required: true
        // }
    },
    mounted() {
        // console.log("this.apartment.buttons[0]", this.apartment.buttons[0])
        this.currentButton = this.apartment.buttons[0]
    },
    methods: {
        changeContentTab(button) {
            console.log("button", button)
            this.currentButton = button

        },
        removeApartment(id) {
            this.$store.commit('apartmentsCompareRemove', id)
            // if (Object.keys(this.apartmentsCompare).filter(apt => (this.apartmentsCompare[apt])).length === 0) {
            //     this.$router.push({ name: 'apartments', params: { projectId: this.currentProjectId } })
            // }
        },
        /*close(){
            this.$router.push({name: 'home'})
        }*/
        contentChange(button) {
            console.log("button", button)
            this.currentButton = button
        },
        contentType() {
            if (this.currentButton && this.currentButton.acf_fc_layout) {
                return this.currentButton.acf_fc_layout
            }
            return null
        },
    },
}
</script>
<style scoped lang="scss">
.apartment-block {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    margin-bottom: 16px;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 25rem !important;

    &.v-enter-active,
    &.v-leave-active {
        transition: transform 0.5s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: translateX(100vw);
    }

    .apartment-block-content {

        width: 35%;
        border-radius: 0rem 0.3125rem 0.3125rem 0rem;
        background: #202020;
        .bigNumber {
            font-size: 8rem;
            font-style: normal;
            font-weight: 400;
            background: linear-gradient(180deg, rgba(234, 234, 234, 0.90) 0%, #737373 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          

        }
        .infoWrapper{
            padding: 3rem;
        }

    }

    .apartment-block-card {
        position: relative;

        border-right: solid 1px #fff;
        flex-grow: 1;
        width: 40rem;

        &.v-enter-active,
        &.v-leave-active {
            transition: opacity .5s ease;
        }

        &.v-enter-from,
        &.v-leave-to {
            opacity: 0;
        }

        iframe {
            width: 100%;
            height: calc(100% - 5px);
        }

        nav.header-tabs {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            background-color: #000;
            padding: 0 12px;

            ul {
                display: flex;
                min-height: 50px;
                align-items: center;
                gap: 10px;

                li button {
                    color: #fff;
                    font-size: 18px;

                    &.active {
                        color: var(--primary-light);
                    }
                }
            }
        }

    }


    .apartment-block-card-content,
    .apartment-block-card-content .gallery-slides {
        height: 100%;

        .img-container {
            height: 100%;
            text-align: center;
            img {
                height: 98%;
            }
        }
    }

    .apartment-block-card-toggle {
        position: absolute;
        right: 100%;
        background-color: #000;
        height: 50px;
        border-radius: 5px 0 0 5px;
        margin-right: 1px;
        top: 50%;
        border: solid 1px #00DBCE;
        border-right-color: #000;
        transform: translateY(-50%);
    }
}

.ab-header {
    border-radius: 0rem 0.3125rem 0rem 0rem;
    background: rgba(53, 53, 53, 0.90);
    padding: 1rem;

    .close-btn {
        margin-right: auto;
    }
}

.apt-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .flex-item {
        flex: 1;
        text-align: right
    }
}

.ab-title {
    flex-grow: 0;
    border-bottom: solid 1px #6c6c6c;
    padding: 5px 0;
    display: flex;
    align-items: center;
}

.ab-price {
    display: flex;
    justify-content: space-between;
    border: solid 1px #777878;
    border-radius: 5px;
    padding: 6px 12px;
    margin-top: 15px;
}

.apt-buttons {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    border-radius: 5px;
    align-self: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 10px 0; 

    button {
        flex: 0 0 calc(50% - .5rem) ;       /* Don't grow, don't shrink, base width of 50% */
        border-radius: 6.25rem;
        border: 1px solid var(--grey-grey-3, #B3B3B3);
        color: 1px solid var(--grey-grey-3, #B3B3B3);
        box-sizing: border-box; /* So the border is included in the width */
        padding: 10px;  
        background: transparent;   
        box-sizing: border-box;
        transition: all .2s ease;
        &:hover{
            border-color: var(--primary, #B3B3B3);
        }
        &.active{
            border-color: var(--primary, #B3B3B3);
        }
    }
}

.is-mobile {
    .apartment-block {
        //display: block;
        flex-flow: column;
        position: relative;
        border: none;
        overflow: hidden;
    }
    .apartment-block-content {
        //height: 4rem;
        width: 100%;
        
        .infoWrapper{
            padding: 0;
            padding-bottom: 1rem;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: rgba(53, 53, 53, 1);
            z-index: 1;
        }
        .apt-info {
            display: block;


        }
        .bigNumber {
            font-size: 3rem;
            position: absolute;
            transform: translateY(-3rem);
            background: white;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          

        }
        .apt-buttons {
            display: flex;
            flex-wrap: nowrap;
            max-width: 100%;
            border-radius: 5px;
            align-self: flex-start;
            justify-content: start;
            width: 100%;
            gap: 0 10px;
            z-index: 10;
            padding-top: 1rem;
            padding-right: 1rem;
            button {
                flex: 0 0 calc(30% - .5rem) ;  
                padding: 0; 
            }
        }

    }
    .apartment-block-card {
        position: relative;
        height: 70%;
        //display: none;
        border-right: solid 1px #fff;
        flex-grow: 1;
        width: 100%;

        &.v-enter-active,
        &.v-leave-active {
            transition: opacity .5s ease;
        }

        &.v-enter-from,
        &.v-leave-to {
            opacity: 0;
        }

        iframe {
            width: 100%;
            height: calc(100% - 5px);
        }

        nav.header-tabs {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            background-color: #000;
            padding: 0 12px;

            ul {
                display: flex;
                min-height: 50px;
                align-items: center;
                gap: 10px;

                li button {
                    color: #fff;
                    font-size: 18px;

                    &.active {
                        color: var(--primary-light);
                    }
                }
            }
        }

    }

}







</style>