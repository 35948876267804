<template>
    <div class="page apartments-compare">
        <transition appear>
            <div class="apartment-blocks scrollable-y">
                <transition-group appear>
                    <template v-for="(aptShow, aptId) in apartmentsCompare" :key="aptId">
                        <div v-if="apartments && apartments[aptId] && aptShow">
                            <ApartmentBlock :apartment="apartments[aptId]"/>

                        </div>
                    </template>
                </transition-group>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ApartmentBlock from "@/components/ApartmentBlock";


export default {
    name: "ApartmentsComparePage",
    components: {
        ApartmentBlock
    },
    computed: {
        apartments() {
            return this.$store.getters.products
        },
        ...mapState(['apartmentsCompare', 'currentProjectId', 'apartmentFavs']),
        ...mapGetters(['isTablet', 'isMobile', 'isDesktop'])
    },
    data() {
        return {
            apartmentContents: {},
            currentContentTab: 0,
            currentContentTabs: {}
        }
    },
    methods: {
        changeContentTab(aptId, tabIndex) {

            this.currentContentTab = tabIndex
            this.currentContentTabs[aptId] = tabIndex
        },
        removeApartment(id) {
            this.$store.commit('apartmentsCompareRemove', id)
            if (Object.keys(this.apartmentsCompare).filter(apt => (this.apartmentsCompare[apt])).length === 0) {
                this.$router.push({ name: 'apartments', params: { projectId: this.currentProjectId } })
            }
        },
        /*close(){
            this.$router.push({name: 'home'})
        }*/
        contentChange(aptId, content) {
            console.log(this.apartments[aptId])
            console.log(this.currentContentTabs)
            this.currentContentTabs[aptId] = 0
            if (this.apartmentContents[aptId] && this.apartmentContents[aptId] === content) {
                this.apartmentContents[aptId] = null
            } else {
                this.apartmentContents[aptId] = content
            }

            setTimeout(() => {
                const aptBlockRef = this.$refs[`aptBlock${aptId}`]
                const galleryRef = this.$refs[`aptBlock${aptId}Gallery`]
                if (aptBlockRef && galleryRef) {
                    if (this.isDesktop) {
                        if (aptBlockRef.style.maxHeight) {
                            const innerHeight = parseInt(aptBlockRef.style.maxHeight) - 18 * 2
                            galleryRef.setSlideHeight(`${innerHeight}px`)
                        }
                    } else {
                        galleryRef.setSlideHeight('50vh')
                    }
                }
            })
        },
        apartmentContentType(aptId) {
            if (this.apartmentContents[aptId] && this.apartmentContents[aptId].acf_fc_layout) {
                return this.apartmentContents[aptId].acf_fc_layout
            }
            return null
        },
        // calcHeight() {
        //     if (this.isDesktop) {
        //         Object.entries(this.$refs).forEach(aptBlock => {
        //             const block = aptBlock[1]
        //             if (block) {
        //                 const rect = block.getBoundingClientRect()
        //                 block.style.maxHeight = `${rect.height}px`
        //             }
        //         })
        //     }
        // }
    },
    mounted() {
    },
    beforeRouteEnter(to, from, next) {
        next(async (vm) => {
            await vm.$store.dispatch('searchProducts')
            if (Object.keys(vm.$store.getters.apartmentsCompare).length === 0) {
                await vm.$router.push({ name: 'apartments', params: { projectId: vm.currentProjectId } })
            } else {
                Object.entries(vm.$store.getters.apartmentsCompare).forEach(comp => {
                    const key = comp[0]
                    //const value = comp[1]
                    vm.apartmentContents[key] = null
                    vm.currentContentTabs[key] = 0
                })
            }

            // vm.calcHeight()
        })
    }
}
</script>

<style scoped lang="scss">
.apartments-compare {
    
    background-color: black;
    height: 100%;

    .apartment-blocks {

        padding: 4rem 6rem;
        max-height: 60rem;

        &.v-enter-active,
        &.v-leave-active {
            transition: transform 1s ease;
        }

        &.v-enter-from,
        &.v-leave-to {
            transform: translateX(100vw);
        }
    }


}


.is-mobile {
    .apartments-compare {
        margin-top: 4rem;
        .apartment-blocks {
            align-items: stretch;
            padding: 0;
            padding-bottom: 12rem;
        }
    }
}</style>
